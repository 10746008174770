// reducers/index.js
import { combineReducers } from 'redux'
import loginReducer from './loginReducer'
import passwordReducer from './passwordReducer'
import fileReducer from './fileReducer'

const rootReducer = combineReducers({
  loginReducer,
  passwordReducer,
  fileReducer,
  // Add other reducers here
})

export default rootReducer
