import { BACKEND_URL } from '../constants'

export const uploadFilesService = async (
  file1,
  file2,
  nationalCode,
  traceId,
  userId,
) => {
  const formData = new FormData()
  formData.append('pdfs', file1)
  formData.append('pdfs', file2)
  formData.append('traceId', traceId)
  formData.append('nationalCode', nationalCode)
  formData.append('userId', userId)

  try {
    const response = await fetch(`${BACKEND_URL}/file/merge`, {
      method: 'POST',
      body: formData, // FormData automatically sets 'Content-Type' to 'multipart/form-data'
    })

    if (!response.ok) {
      throw new Error('Failed to upload files: ' + response.statusText)
    }

    const responseData = await response.json() // Parse response data
    return responseData // Return response data
  } catch (error) {
    throw error // Rethrow the error to propagate it
  }
}

export const saveMergedPdf = async (traceId) => {
  try {
    // Make HTTP request to backend API to save merged PDF
    const response = await fetch(`${BACKEND_URL}/api/save-pdf`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: { traceId },
    })
    const data = await response.json()
    return data
  } catch (error) {
    throw error
  }
}

export const getFilesService = async (filter) => {
  try {
    // Make HTTP request to backend API to save merged PDF

    let url = `${BACKEND_URL}/file`
    if (filter) {
      url = `${BACKEND_URL}/file?filter=${filter}`
    }
    console.log(url)
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const data = await response.json()
    return data
  } catch (error) {
    throw error
  }
}

export const getEmzameFileService = async (traceId) => {
  try {
    // Make HTTP request to backend API to save merged PDF
    const url = `${BACKEND_URL}/file/${traceId}`

    console.log(url)
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const data = await response.json()
    return data
  } catch (error) {
    throw error
  }
}

export const inquiryFileService = async (traceId) => {
  try {
    const url = `${BACKEND_URL}/file/inquiry/${traceId}`
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const data = await response.json()
    return data
  } catch (error) {
    throw error
  }
}
export const deleteFileService = async (traceId) => {
  try {
    // Make HTTP request to backend API to save merged PDF
    const url = `${BACKEND_URL}/file/${traceId}`
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const data = await response.json()
    return data
  } catch (error) {
    throw error
  }
}

export const submitFileService = async (traceId) => {
  try {
    // Make HTTP request to backend API to save merged PDF
    const url = `${BACKEND_URL}/file/submit/${traceId}`
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: { traceId },
    })
    const data = await response.json()
    return data
  } catch (error) {
    throw error
  }
}
