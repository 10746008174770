import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_INFO_FAILED,
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILED,
} from '../actions/actionTypes'

const initialState = {
  // Define the initial state of your application here
  user: {
    isLoggedIn: null,
    username: null,
    email: null,
    phone: null,
    fullname: null,
    // other user-related properties
  },
  auth: {
    token: null,
  },
  error: null,
  loading: null,
  message: null,
}

const passwordReducer = (state = initialState, action) => {
  const res = action.payload
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        message: res.message,
      }
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        token: null,
        user: null,
        error: res.error,
        message: null,
      }
    case USER_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        message: null,
      }
    case USER_INFO_SUCCESS:
      return {
        ...state,
        user: {
          isLoggedIn: false,
          username: res?.username,
          phone: res?.phone,
          email: res?.email,
          fullname: res?.fullname || 'Somaye Khalili',
        },
        auth: {
          token: null,
        },
        loading: false,
        error: null,
        message: null,
      }
    case USER_INFO_FAILED:
      return {
        ...state,
        loading: false,
        token: null,
        user: null,
        error: res.error,
        message: null,
      }
    case SET_NEW_PASSWORD:
      return {
        ...state,
        loading: true,
      }
    case SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { isLoggedIn: false },
        message: res.message,
      }
    case SET_NEW_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: res.error,
      }

    default:
      return state
  }
}

export default passwordReducer
