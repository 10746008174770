import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Container,
  Table,
  Card,
  Form,
  Button,
  Alert,
  Modal,
  Spinner,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import OrderModal from './OrderModal'
import PdfPreviewModal from './PdfPreviewModal'
import {
  getFiles,
  deleteFile,
  submitFile,
  inquiryFile,
} from '../actions/fileActions'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import moment from 'moment-jalaali'
import { BACKEND_URL } from '../constants'

const OrderList = ({}) => {
  const { t, i18n } = useTranslation()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteTraceId, setDeleteTraceId] = useState(null)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [selectedPdfUrl, setSelectedPdfUrl] = useState('')
  const [filter, setFilter] = useState('')

  const loading = useSelector((state) => state.fileReducer.loading)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isRTL = i18n.dir() === 'rtl'

  const files = useSelector((state) => state.fileReducer.files) || [] // Get files from redux store

  useEffect(() => {
    // Dispatch the getFiles action when the component mounts
    dispatch(getFiles(filter, navigate))
  }, [dispatch]) // Dependency array ensures the effect runs only once

  // Fetch files again when success message is displayed
  useEffect(() => {
    if (showSuccessMessage) {
      dispatch(getFiles(filter, navigate))
    }
  }, [dispatch, showSuccessMessage])

  const handleClosePreviewModal = () => {
    setSelectedPdfUrl('')
    setShowPreviewModal(false)
  }

  const handleShowCreateModal = () => {
    setShowCreateModal(true)
  }

  const handleCloseCreateModal = () => {
    setShowCreateModal(false)
  }

  const handleSuccess = () => {
    setShowSuccessMessage(true)
    setSuccessMessage(t('list.actionSuccess'))
    setShowCreateModal(false)
    dispatch(getFiles(filter, navigate))
    setTimeout(() => {
      setShowSuccessMessage(false)
    }, 5000)
  }

  const handleError = () => {
    setShowErrorMessage(true)
    setErrorMessage(t('list.actionError'))
    setTimeout(() => {
      setShowErrorMessage(false)
    }, 5000)
  }

  const handleDelete = (traceId) => {
    setDeleteTraceId(traceId)
    setShowDeleteModal(true)
  }

  const handleSubmit = (traceId) => {
    dispatch(submitFile(traceId, handleSuccess, handleError))
  }

  const handleInquiry = (traceId) => {
    dispatch(inquiryFile(traceId, handleSuccess, handleError))
  }

  const confirmDelete = () => {
    dispatch(deleteFile(deleteTraceId, handleSuccess, handleError))
    setShowDeleteModal(false)
  }

  const formatDate = (createdAt) => {
    return isRTL
      ? moment(createdAt).format('jYYYY/jMM/jDD HH:mm:ss')
      : format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss')
  }

  const handleDownload = (status, filePath, downloadLink) => {
    if (status === 'DRAFT' || status === 'PENDING') {
      window.open(`${BACKEND_URL}/file/${filePath}`, '_blank')
    }
    if (status === 'SUCCESS') {
      window.open(downloadLink)
    }
  }
  const handleSearch = () => {
    dispatch(getFiles(filter, navigate))
  }

  return (
    <Container>
      {loading && (
        <div className='spinner-overlay'>
          <Spinner animation='border' role='status'>
            <span className='sr-only' />
          </Spinner>
        </div>
      )}
      {showSuccessMessage && (
        <Alert variant='success' className='mt-4 mb-4'>
          {successMessage}
        </Alert>
      )}
      {showErrorMessage && (
        <Alert variant='danger' className='mt-4 mb-4'>
          {errorMessage}
        </Alert>
      )}
      <h2 className='mt-5 mb-4'>{t('list.orders')}</h2>

      {/* Search card */}
      <Card className='mb-4'>
        <Card.Body>
          <Form>
            <Form.Group className='mb-3'>
              <Form.Label>{t('list.search')}</Form.Label>
              <Form.Control
                type='text'
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                placeholder={t('list.searchPlaceholder')}
              />
            </Form.Group>
            <Button variant='primary' className='me-2' onClick={handleSearch}>
              {t('list.searchButton')}
            </Button>
            <Button
              variant='primary'
              className='me-2'
              onClick={handleShowCreateModal}
            >
              {t('list.createButton')}
            </Button>
          </Form>
        </Card.Body>
      </Card>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>{t('list.name')}</th>
            <th>{t('list.nationalCode')}</th>
            <th>{t('list.date')}</th>
            <th>{t('list.status')}</th>
            <th>{t('list.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr key={file._id}>
              <td>{index + 1}</td>
              <td>{file.traceId}</td>
              <td>{file.nationalCode}</td>
              <td>{formatDate(file.createdAt)}</td>
              <td>{t(`status.${file.status}`)}</td>
              <td>
                {file.status === 'PENDING' && (
                  <Button
                    className='me-1'
                    variant='primary'
                    size='sm'
                    onClick={() => handleInquiry(file.traceId)}
                  >
                    {t('list.inquiryButton')}
                  </Button>
                )}
                <Button
                  size='sm'
                  className='me-1'
                  variant={file.status === 'SUCCESS' ? 'success' : 'secondary'}
                  onClick={() =>
                    handleDownload(
                      file.status,
                      file.mergedPdfPath,
                      file.downloadLink,
                    )
                  }
                >
                  {file.status === 'SUCCESS'
                    ? t('list.emzamedownload')
                    : t('list.download')}
                </Button>
                {file.status === 'DRAFT' && (
                  <>
                    <Button
                      size='sm'
                      className='me-1'
                      variant='danger'
                      onClick={() => handleDelete(file.traceId)}
                    >
                      {t('list.remove')}
                    </Button>
                    <Button
                      size='sm'
                      className='me-1'
                      variant='primary'
                      onClick={() => handleSubmit(file.traceId)}
                    >
                      {t('list.submit')}
                    </Button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <OrderModal
        show={showCreateModal}
        onHide={handleCloseCreateModal}
        onUploadSuccess={handleSuccess}
      />
      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        dialogClassName={isRTL ? 'rtl-modal' : ''}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('list.deleteConfirmTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('list.deleteConfirmMessage')}</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowDeleteModal(false)}>
            {t('list.cancelButton')}
          </Button>
          <Button variant='danger' onClick={confirmDelete}>
            {t('list.confirmButton')}
          </Button>
        </Modal.Footer>
      </Modal>
      <PdfPreviewModal
        show={showPreviewModal}
        onHide={handleClosePreviewModal}
        pdfUrl={selectedPdfUrl}
      />
    </Container>
  )
}

export default OrderList
