import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { loginUser } from '../actions/loginActions'
import { useNavigate } from 'react-router-dom'

const LoginScreen = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [clientError, setClientError] = useState('')
  const serverError = useSelector((state) => state.loginReducer.error)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogin = (e) => {
    e.preventDefault()
    if (!username || !password) {
      setClientError('Please enter both username and password.')
      return
    }
    setClientError(null)
    dispatch(loginUser(username, password, navigate))
  }

  return (
    <Container className='login-container'>
      <Row className='justify-content-md-center mt-5'>
        <Col xs={12} md={6}>
          <h2 className='text-center mb-4'>{t('login')}</h2>
          {(clientError || serverError) && (
            <Alert variant='danger'>{clientError || serverError}</Alert>
          )}
          <Form className='login-form'>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>{t('username')}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t('usernamePlaceholder')}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label>{t('password')}</Form.Label>
              <Form.Control
                type='password'
                placeholder={t('password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Button variant='primary' type='button' onClick={handleLogin}>
              {t('login')}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default LoginScreen
