import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SET_LOGGED_IN,
  LOGOUT,
} from '../actions/actionTypes'

const initialState = {
  // Define the initial state of your application here
  user: {
    isLoggedIn: null,
    username: null,
    email: null,
    phone: null,
    fullname: null,
    // other user-related properties
  },
  auth: {
    token: null,
  },
  error: null,
  loading: null,
}

const loginReducer = (state = initialState, action) => {
  const res = action.payload
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: {
          isLoggedIn: true,
          username: res?.username,
          phone: res?.phone,
          email: res?.email,
          fullname: res?.fullname || 'Somaye Khalili',
        },
        auth: {
          token: res?.token,
        },
        loading: false,
        error: null,
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        token: null,
        user: null,
        error: res.error || res.message,
      }
    case SET_LOGGED_IN:
      return {
        ...state,
        user: {
          ...state.user,
          isLoggedIn: action.payload,
        },
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export default loginReducer
