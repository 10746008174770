import {
  UPLOAD_PROGRESS,
  UPLOAD_SUCCESS,
  UPLOAD_FAILED,
  GET_FILES_REQUEST,
  GET_FILES_SUCCESS,
  GET_FILES_FAILED,
  SAVE_MERGED_PDF_REQUEST,
  SAVE_MERGED_PDF_SUCCESS,
  SAVE_MERGED_PDF_FAILED,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILED,
  DOWNLOAD_FILE_REQUEST,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAILED,
  SUBMIT_FILE_REQUEST,
  SUBMIT_FILE_SUCCESS,
  SUBMIT_FILE_FAILED,
  INQUIRY_FILE_REQUEST,
  INQUIRY_FILE_SUCCESS,
  INQUIRY_FILE_FAILED,
} from '../actions/actionTypes'
import {
  saveMergedPdf,
  uploadFilesService,
  getFilesService,
  deleteFileService,
  submitFileService,
  inquiryFileService,
} from '../service/fileService'

export const uploadProgress = (progress) => ({
  type: UPLOAD_PROGRESS,
  payload: progress,
})

export const uploadSuccess = (payload) => ({
  type: UPLOAD_SUCCESS,
  payload,
})

export const uploadFailed = (payload) => ({
  type: UPLOAD_FAILED,
  payload,
})

export const saveFileRequest = (payload) => ({
  type: SAVE_MERGED_PDF_REQUEST,
  payload,
})

export const saveFileSuccess = (payload) => ({
  type: SAVE_MERGED_PDF_SUCCESS,
  payload,
})

export const saveFileFailed = (payload) => ({
  type: SAVE_MERGED_PDF_FAILED,
  payload,
})

export const getFilesRequest = (payload) => ({
  type: GET_FILES_REQUEST,
  payload,
})

export const getFilesSuccess = (payload) => ({
  type: GET_FILES_SUCCESS,
  payload,
})

export const getFilesFailed = (payload) => ({
  type: GET_FILES_FAILED,
  payload,
})

export const deleteFileRequest = (payload) => ({
  type: DELETE_FILE_REQUEST,
  payload,
})

export const deleteFileSuccess = (payload) => ({
  type: DELETE_FILE_SUCCESS,
  payload,
})

export const deleteFileFailed = (payload) => ({
  type: DELETE_FILE_FAILED,
  payload,
})

export const downloadFileRequest = (payload) => ({
  type: DOWNLOAD_FILE_REQUEST,
  payload,
})

export const downloadFileSuccess = (payload) => ({
  type: DOWNLOAD_FILE_SUCCESS,
  payload,
})

export const downloadFileFailed = (payload) => ({
  type: DOWNLOAD_FILE_FAILED,
  payload,
})

export const submitFileRequest = (payload) => ({
  type: SUBMIT_FILE_REQUEST,
  payload,
})

export const submitFileSuccess = (payload) => ({
  type: SUBMIT_FILE_SUCCESS,
  payload,
})

export const submitFileFailed = (payload) => ({
  type: SUBMIT_FILE_FAILED,
  payload,
})

export const inquiryFileRequest = (payload) => ({
  type: INQUIRY_FILE_REQUEST,
  payload,
})

export const inquiryFileSuccess = (payload) => ({
  type: INQUIRY_FILE_SUCCESS,
  payload,
})

export const inquiryFileFailed = (payload) => ({
  type: INQUIRY_FILE_FAILED,
  payload,
})

export const uploadFile =
  (
    file1,
    file2,
    nationalCode,
    traceId,
    userId,
    navigate,
    onSuccess,
    onFailure,
  ) =>
  async (dispatch) => {
    dispatch(uploadProgress())
    try {
      const res = await uploadFilesService(
        file1,
        file2,
        nationalCode,
        traceId,
        userId,
      )

      if (res && res.error === null) {
        dispatch(uploadSuccess(res))
        onSuccess()
        //navigate('/List')
      } else {
        // Alert.alert('Please enter valid username and password.')
        dispatch(uploadFailed(res))
        onFailure()
      }
    } catch (error) {
      const errObj = {
        type: 'UPLOAD_FAILED',
        message: error.message ? error.message : 'Upload Failed',
        status: error.status || '500',
      }
      // Alert.alert('Please enter valid username and password.')
      dispatch(uploadFailed(errObj))
    }
  }

export const saveFiles = (traceId, navigate) => async (dispatch) => {
  dispatch(saveFileRequest())
  try {
    const res = await saveMergedPdf(traceId)

    if (res && (res.success || res.error === null)) {
      dispatch(saveFileSuccess()) //todo
      navigate('/List')
    } else {
      // Alert.alert('Please enter valid username and password.')
      dispatch(saveFileFailed(res))
    }
  } catch (error) {
    const errObj = {
      type: 'UPLOAD_FAILED',
      message: error.message ? error.message : 'Upload Failed',
      status: error.status || '500',
    }
    // Alert.alert('Please enter valid username and password.')
    dispatch(saveFileFailed(errObj))
  }
}

export const getFiles = (filter, navigate) => async (dispatch) => {
  dispatch(getFilesRequest())
  try {
    const res = await getFilesService(filter)

    if (res && (res.success || res.error === null)) {
      dispatch(getFilesSuccess(res)) //todo
      // navigate('/List')
    } else {
      // Alert.alert('Please enter valid username and password.')
      dispatch(getFilesFailed(res))
    }
  } catch (error) {
    const errObj = {
      type: 'GET_FILES_FAILED',
      message: error.message ? error.message : 'get files Failed',
      status: error.status || '500',
    }
    // Alert.alert('Please enter valid username and password.')
    dispatch(getFilesFailed(errObj))
  }
}

export const inquiryFile =
  (traceId, onSuccess, onFailure) => async (dispatch) => {
    dispatch(inquiryFileRequest())
    try {
      const res = await inquiryFileService(traceId)

      if (res && (res.success || res.error === null)) {
        dispatch(inquiryFileSuccess(res)) //todo
        onSuccess()
      } else {
        // Alert.alert('Please enter valid username and password.')
        dispatch(inquiryFileFailed(res))
        onFailure()
      }
    } catch (error) {
      const errObj = {
        type: 'GET_FILES_FAILED',
        message: error.message ? error.message : 'inquiry Failed',
        status: error.status || '500',
      }
      // Alert.alert('Please enter valid username and password.')
      dispatch(inquiryFileFailed(errObj))
      onFailure()
    }
  }

export const deleteFile =
  (traceId, onSuccess, onFailure) => async (dispatch) => {
    dispatch(deleteFileRequest())
    try {
      const res = await deleteFileService(traceId)

      if (res && (res.success || res.error === null)) {
        dispatch(deleteFileSuccess(res)) //todo
        onSuccess()
      } else {
        // Alert.alert('Please enter valid username and password.')
        dispatch(deleteFileFailed(res))
        onFailure()
      }
    } catch (error) {
      const errObj = {
        type: 'DLEETE_FILES_FAILED',
        message: error.message ? error.message : 'delete files Failed',
        status: error.status || '500',
      }
      // Alert.alert('Please enter valid username and password.')
      dispatch(deleteFileFailed(errObj))
      onFailure()
    }
  }

export const submitFile =
  (traceId, onSuccess, onFailure) => async (dispatch) => {
    dispatch(submitFileRequest())
    try {
      const res = await submitFileService(traceId)

      if (res && (res.success || res.error === null)) {
        dispatch(submitFileSuccess(res)) //todo
        onSuccess()
      } else {
        // Alert.alert('Please enter valid username and password.')
        dispatch(submitFileFailed(res))
        onFailure()
      }
    } catch (error) {
      const errObj = {
        type: 'SUBMIT_FILES_FAILED',
        message: error.message ? error.message : 'submit files Failed',
        status: error.status || '500',
      }
      // Alert.alert('Please enter valid username and password.')
      dispatch(submitFileFailed(errObj))
      onFailure()
    }
  }

export const downloadFile = (traceId, onSuccess) => async (dispatch) => {
  dispatch(downloadFileRequest())
  try {
    const res = await downloadFileService(traceId)

    if (res && (res.success || res.error === null)) {
      dispatch(downloadFileSuccess(res)) //todo
      onSuccess()
    } else {
      // Alert.alert('Please enter valid username and password.')
      dispatch(downloadFileFailed(res))
    }
  } catch (error) {
    const errObj = {
      type: 'DOWNLOAD_FILES_FAILED',
      message: error.message ? error.message : 'download files Failed',
      status: error.status || '500',
    }
    // Alert.alert('Please enter valid username and password.')
    dispatch(downloadFileFailed(errObj))
  }
}
