import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Button, Form, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { uploadFile } from '../actions/fileActions'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'

const CreateOrderModal = ({ show, onHide, onUploadSuccess }) => {
  const { t, i18n } = useTranslation()

  // Check the language direction
  const isRTL = i18n.dir() === 'rtl'

  const [nationalCode, setNationalCode] = useState('')
  const [file1, setFile1] = useState(null)
  const [file2, setFile2] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  const userId = useSelector((state) => state.loginReducer.user.email)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!nationalCode || !file1 || !file2) {
      setErrorMessage(t('modal.validationError'))
      return
    }

    const traceId = uuidv4()

    // Dispatch the start upload action when the submit button is clicked
    dispatch(
      uploadFile(
        file1,
        file2,
        nationalCode,
        traceId,
        userId || 'sample@gmail.com',
        navigate,
        onUploadSuccess,
      ),
    )
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName={isRTL ? 'rtl-modal' : ''}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('modal.createTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
          <Form.Group className='mb-3'>
            <Form.Label>{t('modal.nationalCode')}</Form.Label>
            <Form.Control
              type='text'
              value={nationalCode}
              onChange={(e) => setNationalCode(e.target.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>{t('modal.upload1')}</Form.Label>
            <Form.Control
              type='file'
              onChange={(e) => setFile1(e.target.files[0])}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>{t('modal.upload2')}</Form.Label>
            <Form.Control
              type='file'
              onChange={(e) => setFile2(e.target.files[0])}
            />
          </Form.Group>
          <Button variant='primary' type='submit'>
            {t('modal.createButton')}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default CreateOrderModal
