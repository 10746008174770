import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Document, Page } from 'react-pdf'

const PdfPreviewModal = ({ show, onHide, pdfUrl }) => {
  return (
    <Modal show={show} onHide={onHide} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>PDF Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Document file={pdfUrl}>
          <Page pageNumber={1} />
        </Document>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PdfPreviewModal
