// actionTypes.js

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const SET_LOGGED_IN = 'SET_LOGGED_IN'
export const LOGOUT = 'LOGOUT'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'
export const USER_INFO_REQUEST = 'USER_INFO_REQUEST'
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS'
export const USER_INFO_FAILED = 'USER_INFO_FAILED'
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD'
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS'
export const SET_NEW_PASSWORD_FAILED = 'SET_NEW_PASSWORD_FAILED'
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS'
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS'
export const UPLOAD_FAILED = 'UPLOAD_FAILED'
export const SAVE_MERGED_PDF_REQUEST = 'SAVE_MERGED_PDF_REQUEST'
export const SAVE_MERGED_PDF_SUCCESS = 'SAVE_MERGED_PDF_SUCCESS'
export const SAVE_MERGED_PDF_FAILED = 'SAVE_MERGED_PDF_FAILED'
export const GET_FILES_REQUEST = 'GET_FILES_REQUEST'
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS'
export const GET_FILES_FAILED = 'GET_FILES_FAILED'
export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST'
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS'
export const DELETE_FILE_FAILED = 'DELETE_FILE_FAILED'

export const DOWNLOAD_FILE_REQUEST = 'DOWNLOAD_FILE_REQUEST'
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS'
export const DOWNLOAD_FILE_FAILED = 'DOWNLOAD_FILE_FAILED'

export const SUBMIT_FILE_REQUEST = 'SUBMIT_FILE_REQUEST'
export const SUBMIT_FILE_SUCCESS = 'SUBMIT_FILE_SUCCESS'
export const SUBMIT_FILE_FAILED = 'SUBMIT_FILE_FAILED'

export const INQUIRY_FILE_REQUEST = 'INQUIRY_FILE_REQUEST'
export const INQUIRY_FILE_SUCCESS = 'INQUIRY_FILE_SUCCESS'
export const INQUIRY_FILE_FAILED = 'INQUIRY_FILE_FAILED'
