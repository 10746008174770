// actions.js

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SET_LOGGED_IN,
  LOGOUT,
} from './actionTypes'
import { authenticateUser } from '../service/authService' // Import the authentication service
import { saveToken, deleteToken, decodeToken } from '../utils'

export const loginRequest = () => ({
  type: LOGIN_REQUEST,
})

export const logoutUser = () => ({
  type: LOGOUT,
})

export const loginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
})

export const loginFailure = (payload) => ({
  type: LOGIN_FAILURE,
  payload,
})

export const setLoggedIn = (isLoggedIn) => ({
  type: SET_LOGGED_IN,
  payload: isLoggedIn,
})

export const loginUser = (username, password, navigate) => async (dispatch) => {
  dispatch(loginRequest())
  try {
    const res = await authenticateUser(username, password) // Call authentication service

    if (res && (res.success || res.error === null)) {
      const authToken = res.token

      await saveToken(authToken)
      const userDetail = decodeToken(authToken)

      dispatch(loginSuccess(userDetail))

      navigate('/List')
    } else {
      // Alert.alert('Please enter valid username and password.')
      dispatch(loginFailure(res))
    }
  } catch (error) {
    const errObj = {
      type: 'AUTHENTICATION_FAILED',
      message: error.message ? error.message : 'Authentication Failed',
      status: error.status || '500',
    }
    // Alert.alert('Please enter valid username and password.')
    dispatch(loginFailure(errObj))
  }
}

export const logout = () => async (dispatch) => {
  try {
    await deleteToken()
    dispatch(logoutUser())
    // TODO: Remove backend sesson through POST /logout
  } catch (error) {
    console.error('Error during logout:', error)
  }
}
