import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import logo from '../assets/image.png' // Import your logo image
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import '../styles/rtl.css' // Default to RTL

const Navbar = () => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (i18n.language === 'en') {
      import('../styles/ltr.css').then(() => {
        document.body.classList.add('ltr')
        document.body.classList.remove('rtl')
      })
    } else {
      import('../styles/rtl.css').then(() => {
        document.body.classList.add('rtl')
        document.body.classList.remove('ltr')
      })
    }
  }, [i18n.language])

  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }

  return (
    <nav className='navbar navbar-expand-lg navbar-light bg-light'>
      <div className='container-fluid'>
        <Link className='navbar-brand' to='/'>
          <img src={logo} alt='Logo' height='40' />
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
            <li className='nav-item'>
              <Link className='nav-link' to='/'>
                {t('navbar.home')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/about'>
                {t('navbar.about')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/contact'>
                {t('navbar.contact')}
              </Link>
            </li>
          </ul>
          <div className='nav-item'>
            {i18n.language === 'en' ? (
              <button
                className='btn btn-link'
                onClick={() => changeLanguage('fa')}
              >
                فارسی
              </button>
            ) : (
              <button
                className='btn btn-link'
                onClick={() => changeLanguage('en')}
              >
                English
              </button>
            )}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
