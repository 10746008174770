import AsyncStorage from '@react-native-async-storage/async-storage'
import { jwtDecode } from 'jwt-decode'

// Assuming 'authToken' is the authentication authToken obtained upon login
export const saveToken = async (authToken) => {
  console.log('### save authToken', authToken)
  try {
    await AsyncStorage.setItem('authToken', authToken)
    console.log('Token saved successfully')
  } catch (error) {
    console.error('Error saving authToken:', error)
  }
}

export const getToken = async () => {
  try {
    const authToken = await AsyncStorage.getItem('authToken')
    if (authToken !== null) {
      return authToken
    } else {
      console.log('Token not found')
      return null
    }
  } catch (error) {
    console.error('Error retrieving authToken:', error)
    return null
  }
}

export const deleteToken = async () => {
  try {
    await AsyncStorage.removeItem('authToken')
    console.log('Token deleted successfully')
  } catch (error) {
    console.error('Error deleting authToken:', error)
  }
}

export const decodeToken = (authToken) => {
  try {
    if (authToken) {
      const decodedToken = jwtDecode(authToken)
      return decodedToken
    } else {
      return false
    }
  } catch (error) {
    console.error('Error decoding authToken:', error)
    return false // Treat as expired
  }
}

/**
 *
 * @returns If session has not expired yet return decoded detail
 */
export const getUserDetails = async () => {
  const authToken = await getToken()
  const decodedToken = decodeToken(authToken)
  if (decodedToken) {
    // Get expiry timestamp from decoded authToken
    const expiryTime = decodedToken.exp
    // Get current timestamp
    const currentTime = Math.floor(Date.now() / 1000)
    // Check if expiry time is greater than current time
    return expiryTime > currentTime ? decodedToken : false
  } else {
    return false
  }
}
