import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './configureStore' // Import your Redux store
import Navbar from './components/Navbar' // Import your Navbar component
import OrderList from './screens/OrderList' // Import your screens
import Login from './screens/Login'

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/List' element={<OrderList />} />
        </Routes>
      </Router>
    </Provider>
  )
}

export default App
