// authService.js
import { BACKEND_URL } from '../constants'

export const authenticateUser = async (username, password) => {
  console.log('auth service req')
  try {
    // Perform authentication logic here (e.g., make API request)
    const response = await fetch(`${BACKEND_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'sample basic auth',
      },
      body: JSON.stringify({ username, password }),
    })

    if (!response.ok) {
      throw new Error('Authentication failed')
    }

    // If authentication succeeds, return response data (if needed)
    return await response.json() // If your API returns data upon successful login
  } catch (error) {
    throw new Error('Authentication failed')
  }
}

export const resetPasswordService = async (username) => {
  try {
    // Perform authentication logic here (e.g., make API request)
    const response = await fetch(`${BACKEND_URL}/password/reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'sample basic auth',
      },
      body: JSON.stringify({ username }),
    })

    if (response.error) {
      throw new Error(response.error)
    }

    // If reset Password succeeds, return response data (if needed)
    return await response.json() // If your API returns data upon successful login
  } catch (error) {
    throw new Error(error.message || 'reset Password failed')
  }
}

export const getUserInfoByTraceId = async (traceId) => {
  console.log('getUserInfoByTraceId', traceId)
  try {
    // Perform authentication logic here (e.g., make API request)
    const response = await fetch(`${BACKEND_URL}/users?traceId=${traceId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'sample basic auth',
      },
    })

    if (response.error) {
      console.log(response)
      throw new Error(response.error)
    }

    return await response.json() // If your API returns data upon successful login
  } catch (error) {
    throw new Error(error.message || 'reset Password failed')
  }
}

export const saveNewPasswordService = async (newPassword, username) => {
  console.log('<<<< saveNewPassword service >>>>', newPassword, username)
  try {
    const response = await fetch(`${BACKEND_URL}/password`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'sample basic auth',
      },
      body: JSON.stringify({ newPassword, username }),
    })

    if (response.error) {
      console.log(response)
      throw new Error(response.error)
    }
    // If set Password succeeds, return response data (if needed)
    return await response.json() // If your API returns data upon successful login
  } catch (error) {
    throw new Error(error.message || 'set new Password failed')
  }
}
