// reducers.js
import {
  UPLOAD_PROGRESS,
  UPLOAD_SUCCESS,
  UPLOAD_FAILED,
  GET_FILES_REQUEST,
  GET_FILES_SUCCESS,
  GET_FILES_FAILED,
  INQUIRY_FILE_REQUEST,
  SUBMIT_FILE_REQUEST,
  SUBMIT_FILE_SUCCESS,
  SUBMIT_FILE_FAILED,
  INQUIRY_FILE_SUCCESS,
  INQUIRY_FILE_FAILED,
} from '../actions/actionTypes'

const initialState = {
  uploadProgress: 0,
}

const reducer = (state = initialState, action) => {
  const res = action.payload

  switch (action.type) {
    case UPLOAD_PROGRESS:
      return {
        ...state,
        loading: true,
      }
    case UPLOAD_SUCCESS:
      return {
        ...state,
        file: {
          traceId: res.traceId,
        },
        loading: false,
        error: null,
      }
    case UPLOAD_FAILED:
      return {
        ...state,
        loading: false,
        token: null,
        user: null,
        error: res.error || res.message,
      }
    case GET_FILES_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case GET_FILES_SUCCESS:
      return {
        ...state,
        files: res.data,
        loading: false,
        error: null,
      }
    case GET_FILES_FAILED:
      return {
        ...state,
        loading: false,
        token: null,
        user: null,
        error: res.error || res.message,
      }
    case SUBMIT_FILE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case SUBMIT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case SUBMIT_FILE_FAILED:
      return {
        ...state,
        loading: false,
      }
    case INQUIRY_FILE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case INQUIRY_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case INQUIRY_FILE_FAILED:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default reducer
