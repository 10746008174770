// i18n.js
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import faTranslation from './locales/fa.json'
import enTranslation from './locales/en.json'

const resources = {
  fa: {
    translation: faTranslation,
  },
  en: {
    translation: enTranslation,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'fa', // Default language
  fallbackLng: 'en', // Fallback language
  interpolation: {
    escapeValue: false, // React already does escaping
  },
})

export default i18n
